<template>
<!-- eslint-disable max-len -->
  <section class="Page Vip">
    <div class="Vip-Ranks">
      <h2 class="Title Title--type-h2 Page-Title">
        {{ $t('vipPage.title') }} <span class="Colored">{{ $t('vipPage.ranks.title') }}</span>
      </h2>
      <div class="Page-Introduction">
        <p>
          {{ $t('vipPage.ranks.text') }}
        </p>
      </div>
      <div class="Cards Vip-RankCards">
        <div class="Card Vip-RankCard">
          <div class="Vip-CardContent">
            <div class="Vip-RankName">
              Bronze
            </div>
            <div class="Vip-Level">
              Level 1
            </div>
            <div class="Vip-Points">
              <div class="Vip-Min">
                Min <span class="Vip-Num">0</span>
              </div>
              <div class="Vip-Max">
                Max <span class="Vip-Num">400</span>
              </div>
            </div>
          </div>
          <div class="Vip-RankImage">
            <img src="@/assets/img/bronze.png" />
          </div>
        </div>
        <div class="Card Vip-RankCard">
          <div class="Vip-CardContent">
            <div class="Vip-RankName">
              Silver
            </div>
            <div class="Vip-Level">
              Level 2
            </div>
            <div class="Vip-Points">
              <div class="Vip-Min">
                Min <span class="Vip-Num">401</span>
              </div>
              <div class="Vip-Max">
                Max <span class="Vip-Num">1000</span>
              </div>
            </div>
          </div>
          <div class="Vip-RankImage">
            <img src="@/assets/img/silver.png" />
          </div>
        </div>
        <div class="Card Vip-RankCard">
          <div class="Vip-CardContent">
            <div class="Vip-RankName">
              Gold
            </div>
            <div class="Vip-Level">
              Level 3
            </div>
            <div class="Vip-Points">
              <div class="Vip-Min">
                Min <span class="Vip-Num">1001</span>
              </div>
              <div class="Vip-Max">
                Max <span class="Vip-Num">2000</span>
              </div>
            </div>
          </div>
          <div class="Vip-RankImage">
            <img src="@/assets/img/gold.png" />
          </div>
        </div>
        <div class="Card Vip-RankCard">
          <div class="Vip-CardContent">
            <div class="Vip-RankName">
              Platinum
            </div>
            <div class="Vip-Level">
              Level 4
            </div>
            <div class="Vip-Points">
              <div class="Vip-Min">
                Min <span class="Vip-Num">2001</span>
              </div>
              <div class="Vip-Max">
                Max <span class="Vip-Num">20000</span>
              </div>
            </div>
          </div>
          <div class="Vip-RankImage">
            <img src="@/assets/img/platinum.png" />
          </div>
        </div>
        <div class="Card Vip-RankCard">
          <div class="Vip-CardContent">
            <div class="Vip-RankName">
              Diamond
            </div>
            <div class="Vip-Level">
              Level 5
            </div>
            <div class="Vip-Points Vip-Num">
              Invitation only
            </div>
          </div>
          <div class="Vip-RankImage">
            <img src="@/assets/img/diamond.png" />
          </div>
        </div>
        <div class="Card Vip-RankCard">
          <div class="Vip-CardContent">
            <div class="Vip-RankName">
              Red Diamond
            </div>
            <div class="Vip-Level">
              Level 6
            </div>
            <div class="Vip-Points Vip-Num">
              Invitation only
            </div>
          </div>
          <div class="Vip-RankImage">
            <img src="@/assets/img/red_diamond.png" />
          </div>
        </div>
      </div>
      <div class="Btn Btn--color Btn--centered Vip-Btn" @click="openLogin()">
        {{ $t('buttons.joinClub') }}
      </div>
    </div>
    <div class="Vip-Club">
      <h2 class="Title Title--type-h2 Page-Title">
        {{ $t('vipPage.title') }} <span class="Colored">{{ $t('vipPage.club.title') }}</span>
      </h2>
      <div class="Page-Introduction">
        <p>
          {{ $t('vipPage.club.text') }}
        </p>
      </div>
      <div class="Cards Vip-Cards">
        <div class="Card Vip-Card">
          <img class="Card-Image Vip-CardImage" src="@/assets/img/vip_casino.svg" />
          <div class="Card-Name">
            {{ $t('vipPage.club.bonus1.title') }}
          </div>
          <div class="Card-Text">
            {{ $t('vipPage.club.bonus1.text') }}
          </div>
        </div>
        <div class="Card Vip-Card">
          <img class="Card-Image Vip-CardImage" src="@/assets/img/vip_levels.svg" />
          <div class="Card-Name">
            {{ $t('vipPage.club.bonus2.title') }}
          </div>
          <div class="Card-Text">
            {{ $t('vipPage.club.bonus2.text') }}
          </div>
        </div>
        <div class="Card Vip-Card">
          <img class="Card-Image Vip-CardImage" src="@/assets/img/manager.svg" />
          <div class="Card-Name">
            {{ $t('vipPage.club.bonus3.title') }}
          </div>
          <div class="Card-Text">
            {{ $t('vipPage.club.bonus3.text') }}
          </div>
        </div>
      </div>
      <div class="Btn Btn--color Btn--centered Vip-Btn" @click="openLogin()">
        {{ $t('buttons.joinClub') }}
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/mixins/auth';

export default {
  name: 'VIPClub',
  mixins: [auth],
  metaInfo() {
    return {
      title: this.$t('metaInfo.vip.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.vip.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.Vip {
  padding-left: 16px;
  padding-right: 16px;

  &-Ranks {
    margin-bottom: 58px;

    @media(min-width: $screen-m) {
      margin-bottom: 48px;
    }

    @media(min-width: $screen-l) {
      margin-bottom: 58px;
    }
  }

  &-RankCards {
    display: block;
    margin-bottom: 36px;

    @media (min-width: $screen-s) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-RankCard {
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    width: 100%;
    min-height: 129px;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 26px 16px;
    text-align: left;

    @media (min-width: $screen-s) {
      width: calc(50% - 5px);
    }

    @media (min-width: $screen-m) {
      width: calc(33.33333333% - 5px);
      padding: 22px 14px;
      min-height: 122px;
    }

    @media (min-width: $screen-l) {
      padding: 30px 24px;
      min-height: 156px;
    }

    @media (min-width: $screen-xl) {
      min-height: 204px;
      padding: 40px;
    }
  }

  &-CardContent {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-RankImage {
    width: 32.16080402%;
  }

  &-RankName {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.18;
    color: var(--color-text-main);

    @media (min-width: $screen-l) {
      font-size: 22px;
    }

    @media (min-width: $screen-xl) {
      font-size: 32px;
    }
  }

  &-Level {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.18;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);

    @media (min-width: $screen-m) {
      font-size: 12px;
    }

    @media (min-width: $screen-l) {
      font-size: 14px;
    }

    @media (min-width: $screen-xl) {
      font-size: 16px;
    }
  }

  &-Points {
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.18;
    letter-spacing: 0.09em;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;

    @media (min-width: $screen-l) {
      font-size: 16px;
    }

    @media (min-width: $screen-xl) {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &-Min {
    margin-right: 13px;
  }

  &-Num {
    color: var(--color-main1);
    font-weight: 700;
  }

  &-Cards {
    display: block;
    margin-bottom: 46px;

    @media (min-width: $screen-m) {
      display: flex;
    }
  }

  &-Card {
    width: 100%;

    @media (min-width: $screen-m) {
      padding: 50px 30px;
    }
  }

  &-CardImage {
    height: 142px;
    margin-bottom: 32px;
  }

  &-Btn {
    white-space: nowrap;
  }
}
</style>
